import Footer from '@src/components/public_components/Footer';
import ChristmasLights from '@src/components/shared/ChristmasLights';
import './Layouts.scss';
const PublicLayout = ({ children }) => (
  <div className="layout">
    <ChristmasLights />
    {children}
    {/* <div id="content-wrap"></div> */}
    <Footer />
  </div>
);

export default PublicLayout;
